import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { GlobalProviderService } from './core/services/global-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'compensas-frontend';
  theme = {
    primaryColor: '#007bff',
    secondaryColor: '#6c757d',
    // Defina o tema padrão aqui
  };
  constructor(
    private themeService: ThemeService,
    private globalProviderService: GlobalProviderService
  ) {}

  ngOnInit() {
    // Defina o tema inicial
    this.themeService.setTheme(this.theme);
  }
}
