import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface Theme {
  primaryColor: string;
  secondaryColor: string;
  // Adicione outras propriedades do tema conforme necessário
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<Theme>({
    primaryColor: '#007bff',
    secondaryColor: '#6c757d',
    // Defina o tema padrão aqui
  });

  theme$ = this.themeSubject.asObservable();

  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  setTheme(newTheme: Theme) {
    this.themeSubject.next(newTheme);

    // Atualiza as variáveis CSS no documento
    const root = this.document.documentElement;
    root.style.setProperty('--primary-color', newTheme.primaryColor);
    root.style.setProperty('--secondary-color', newTheme.secondaryColor);
  }
}
