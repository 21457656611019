import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputComponent } from './components/input/input.component';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { NewPasswordModalComponent } from './components/new-password-modal/new-password-modal.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableComponent } from './components/table/table.component';
import { TransportIconPipe } from './pipes/transport-icon.pipe';
import { CreditManagementComponent } from './components/credit-management/credit-management.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicModalComponent } from './components/dynamic-modal/dynamic-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CardComponent,
    ChangePasswordModalComponent,
    DropdownComponent,
    InputComponent,
    LogoutModalComponent,
    NewPasswordModalComponent,
    SettingsModalComponent,
    SidebarComponent,
    TableComponent,
    TransportIconPipe,
    CreditManagementComponent,
    CertificateComponent,
    DynamicTableComponent,
    DynamicModalComponent

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CardComponent,
    ChangePasswordModalComponent,
    DropdownComponent,
    InputComponent,
    LogoutModalComponent,
    NewPasswordModalComponent,
    SettingsModalComponent,
    SidebarComponent,
    TableComponent,
    TransportIconPipe,
    CreditManagementComponent

  ]
})
export class SharedModule { }
