import { Component, OnInit, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { IGetCardsResponse } from 'src/app/shared/models/Cards';
type SidebarSelector = 'dashboard' | 'settings' | 'appointments' | 'creditManagement';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit , OnDestroy{

  sidebarSelector: SidebarSelector = 'dashboard';
  logoutModalVisible: boolean = false;
  windowWidth: number = window.innerWidth;

  distance: number = 0;
  compensation: number = 0;
  ccExchangeRate: number = 0;
  avoided: number = 0;
  credits: number = 0;
  totalValue: number = 0;
  appointments: number = 0;
  patients: number = 0;
  physicians: number = 0;
  healthProfessionals: number = 0;
  medicalSpecialties: number = 0;
  car: number = 0;
  airplane: number = 0;
  motorcycle: number = 0;
  publicTransport: number = 0;
  bicycle: number = 0;
  walk: number = 0;
  train = 0;
  ferries = 0;
  truck = 0;
  city = 0;
  bus = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCardsData();
    this.windowWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }

  getCardsData(): void {
    const userId = this.authService.getCookie('@appCompenSas:useId');
    this.authService.getCards(userId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (cards: IGetCardsResponse) => {
        this.distance = cards.distance;
        this.car = cards.car;
        this.airplane = cards.airplane;
        this.train = cards.train;
        this.ferries = cards.ship;
        this.truck = cards.truck;
        this.credits = cards.generatedCC;
        this.avoided = cards.CO2;
        this.city = cards.cities;
        this.medicalSpecialties = cards.doctorSpecialities;
        this.physicians = cards.doctors;
        this.patients = cards.patients;
        this.appointments = cards.appointments;
        this.bus = cards.bus;
        this.compensation = cards.trees;
        this.totalValue = cards.totalValue;
        this.healthProfessionals = cards.doctors;
        this.ccExchangeRate = cards.ccExchangeRate;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching cards data:', err);
      }
    });
  }

  setSidebarSelector(value: SidebarSelector): void {
    this.sidebarSelector = value;
  }

  setLogoutModalVisible(visible: boolean): void {
    this.logoutModalVisible = visible;
  }




}
