import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

type Title =
  | 'Airplane'
  | 'Avoided'
  | 'Ship'
  | 'Bus'
  | 'Car'
  | 'City'
  | 'Compensation'
  | 'Appointments'
  | 'Credits'
  | 'Distance'
  | 'Health Professionals'
  | 'Medical Specialties'
  | 'Patients'
  | 'Physicians'
  | 'Train'
  | 'Truck'
  | 'Daily quote'
  | 'Amount';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnChanges {
  @Input() title: Title = 'Airplane';
  @Input() variant: 'default' | 'transport' = 'default';
  @Input() color: 'black' | 'green' | 'red' = 'black';
  @Input() informationType: string = '';
  @Input() informationValue: number = 0;

  value: number = 0;
  valueModifier: string = '';

  ngOnInit(): void {
    this.updateValueAndModifier();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['informationValue'] || changes['title']) {
      this.updateValueAndModifier();
    }
  }

  private updateValueAndModifier(): void {
    const valuesModifier = [
      '',
      '',
      'thousands ',
      'millions ',
      'billions ',
      'trillions ',
      'quadrillions ',
      'quintillions ',
      'sextillions ',
      'septillions ',
      'octillions ',
      'nonillions ',
      'decillions ',
      'undecillions ',
      'duodecillions '
    ];

    let modifierIndex = 0;
    let modifiedValue = this.informationValue;

    const titlesWithModifiers = [
      'Distance', 'Compensation', 'Credits', 'Avoided', 'Car', 'Bus',
      'Train', 'Truck', 'Airplane', 'Ship'
    ];

    if (titlesWithModifiers.includes(this.title)) {
      while (modifiedValue / 1000 >= 1) {
        modifiedValue /= 1000;
        modifierIndex++;
      }
    }

    this.value = modifiedValue;
    this.valueModifier = valuesModifier[modifierIndex];
  }

  getColor(): string {
    switch (this.color) {
      case 'black':
        return '#000000'; // or any other color code
      case 'green':
        return '#00FF00'; // or any other color code
      case 'red':
        return '#FF0000'; // or any other color code
      default:
        return '#000000'; // default color
    }
  }
}
