<div class="full-width-container">
  <!-- Modal para nova senha -->
  <div *ngIf="newPasswordModalOpen" class="modal-overlay">
    <app-new-password-modal (close)="newPasswordModalOpen = false"></app-new-password-modal>
  </div>

  <!-- Modal para mudança de senha -->
  <div *ngIf="changePasswordModalOpen" class="modal-overlay">
    <div class="modal-content">
      <app-change-password-modal [code]="code" (close)="changePasswordModalOpen = false"></app-change-password-modal>
    </div>
  </div>
  <div class="login-sidebar">
    <div class="login-container">
      <img [src]="code === null ? 'assets/Logo/compensas.png' : '../assets/Logo/compensas.png'" class="login-logo" />
      <div class="bottom-side">
        <div class="login-input-label-container">
          <h1 class="login-title">{{ 'login.title' | translate }}</h1>
          <form [formGroup]="loginForm">
            <app-input
              id="email-input"
              [placeholder]="'login.email' | translate"
              formControlName="email"
              [width]="windowWidth > 800 ? '25vw' : '55vw'"
            ></app-input>
            <app-input
              id="password-input"
              [placeholder]="'login.password' | translate"
              type="password"
              formControlName="password"
              [width]="windowWidth > 800 ? '25vw' : '55vw'"
              [isPassword]="true"
            ></app-input>
          </form>
        </div>

        <div class="language-selector">
          <label class="language-selector-label" for="language">{{ 'login.language' | translate }}</label>
          <select  name="language" id="language" (change)="changeLanguage($event)">
            <option value="en" [selected]="currentLang === 'en'">🇺🇸</option>
            <option value="pt" [selected]="currentLang === 'pt'">🇧🇷</option>
          </select>
        </div>
        <div class="buttons-container">
          <button class="login-button" (click)="login()">{{ 'login.button' | translate }}</button>
          <button class="forgot-password-button" (click)="openNewPasswordModal()">{{ 'login.forgot' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <img *ngIf="windowWidth > 800" [src]="code === null ? './assets/LoginImage/LoginPageImage.svg' : '../assets/LoginImage/LoginPageImage.svg'" class="login-image" />
</div>
