// src/app/shared/guards/auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Verifica se o token está presente no cookie
    const token = this.authService.getCookie('@appCompenSas:token');

    if (token) {
      // Se o token estiver presente, o usuário está autenticado
      return true;
    } else {
      // Se o token não estiver presente, redireciona para a página de login
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
