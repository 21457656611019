import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { SidebarService } from '../../../core/services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() active: 'dashboard' | 'settings' | 'appointments' | 'creditManagement' = 'dashboard';
  @Output() selectorChanged = new EventEmitter<'dashboard' | 'settings' | 'appointments' | 'creditManagement'>();
  @Output() logoutClicked = new EventEmitter<void>();

  buttons = [
    {
      key: 'dashboard' as const,
      label: 'DASHBOARD',
      activeIcon: 'ActiveDashboardIcon.svg',
      unactiveIcon: 'UnactiveDashboardIcon.svg',
    },
    {
      key: 'appointments' as const,
      label: 'APPOINTMENTS',
      activeIcon: 'ActiveAppointmentsIcon.svg',
      unactiveIcon: 'UnactiveAppointmentsIcon.svg',
    },
    {
      key: 'settings' as const,
      label: 'SETTINGS',
      activeIcon: 'ActiveSettingsIcon.svg',
      unactiveIcon: 'UnactiveSettingsIcon.svg',
    },
    {
      key: 'creditManagement' as const,
      label: 'Credit Management',
      activeIcon: 'ActiveCreditManagement.svg',
      unactiveIcon: 'UnactiveCreditManagement.svg',
    },
  ];

  windowWidth = 1024;
  id: string;

  constructor(private sidebarService: SidebarService) {
    this.id = this.sidebarService.getUserId();
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
  }

  setActive(active: 'dashboard' | 'settings' | 'appointments' | 'creditManagement'): void {
    this.selectorChanged.emit(active);
  }

  showLogoutModal(): void {
    this.logoutClicked.emit();
  }

  getLogoSrc(): string {
    // return `assets/Logo/compensas${this.windowWidth < 800 ? '_small' : ''}.png`;
    return `assets/Logo/compensas${this.windowWidth < 800 ? '' : ''}.png`;

  }

  getButtonIcon(key: 'dashboard' | 'appointments' | 'settings' | 'creditManagement'): string {
    const button = this.buttons.find(btn => btn.key === key);
    return button ? `assets/${this.active === key ? button.activeIcon : button.unactiveIcon}` : '';
  }

  getProfileImageSrc(): string {
    return this.id === 'ca3310c7-fc3d-47fc-a3ff-9e0694b9e730' ? 'assets/moinhos_de_vento.png' : 'assets/sassmart.jpeg';
  }

  getProfileName(): string {
    return this.id === 'ca3310c7-fc3d-47fc-a3ff-9e0694b9e730' ? 'Moinhos de Vento' : 'SAS Smart';
  }
}
