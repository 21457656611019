// src/app/shared/components/change-password-modal/change-password-modal.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  @Input() setOpen?: (open: boolean) => void;
  @Input() code: string | null = null;

  passwordForm: FormGroup;
  errorVisible = false;
  windowWidth: number = 1024;

  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.updateWindowWidth.bind(this));
  }

  updateWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  getColor(color: 'black' | 'green' | 'red'): string {
    switch (color) {
      case 'black':
        return '#000000';
      case 'green':
        return '#00FF00';
      case 'red':
        return '#FF0000';
      default:
        return '#000000';
    }
  }

  async onConfirmClick(): Promise<void> {
    if (this.passwordForm.valid && this.passwordForm.get('password')?.value === this.passwordForm.get('confirmPassword')?.value) {
      if (this.setOpen) {
        this.setOpen(false);
      }
      // Corrigido para passar dois argumentos
      await this.userService.changePassword(
        this.code ?? '',
        this.passwordForm.get('password')?.value ?? ''
      );
    } else {
      this.errorVisible = true;
    }
  }
}
