<div class="card-container" [ngClass]="variant">
  <p class="card-title" [ngClass]="variant">{{ title }}</p>
  <div class="body-container">
    <div class="information-group">
      <div class="colored-detail" [ngStyle]="{ backgroundColor: getColor() }"></div>
      <div class="information-container">
        <p class="information-value">
          {{ title === 'Distance' || title === 'Compensation' || title === 'Credits' || title === 'Avoided' || title === 'Car' || title === 'Bus' || title === 'Train' || title === 'Truck' || title === 'Airplane' || title === 'Ship' || title === 'Amount' ? value.toFixed(2) : value.toFixed(0) }}
        </p>
        <p class="information-type">
          {{ valueModifier }}{{ title === 'Distance' || title === 'Avoided' ? 'of ' : '' }}{{ informationType }}
        </p>
      </div>
    </div>
    <img class="card-icon" [src]="'/assets/CardIcons/' + title.replace(' ', '') + 'Icon.svg'" />
  </div>
</div>
