import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export function initializeApp(translate: TranslateService): () => Observable<void> {
  return (): Observable<void> => {
    const defaultLang = 'en'; // Defina aqui a linguagem padrão
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang);
    return of();
  };
}
