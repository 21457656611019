import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppointmentsService } from 'src/app/core/services/appointments.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';

interface Speciality {
  speciality: string;
  number: number;
}

@Component({
  selector: 'app-specialties-chart',
  templateUrl: './specialties-chart.component.html',
  styleUrls: ['./specialties-chart.component.scss']
})
export class SpecialtiesChartComponent implements OnInit {
  public chartData$: Observable<{ name: string; value: number }[]> = of([]);
  public colorScheme: Color = {
    name: 'custom',
    selectable: true,
    group: 'ordinal' as ScaleType,
    domain: ['#1E2732', '#0EA900', '#D2E8D1', '#80AE7D', '#3C9E37', '#545454']
  };

  constructor(private appointmentsService: AppointmentsService) {}

  ngOnInit(): void {
    this.loadSpecialtiesData();
  }

  private loadSpecialtiesData(): void {
    this.appointmentsService.getSpecialties().subscribe((specialities: Speciality[]) => {
      const data = specialities.map(s => ({
        name: s.speciality,
        value: s.number
      }));
      this.chartData$ = of(data);
    });
  }
}
