<div class="full-width-container">
  <!-- Logout Modal -->
  <div *ngIf="logoutModalVisible" class="logout-modal-wrapper">
    <app-logout-modal (setOpen)="setLogoutModalVisible($event)"></app-logout-modal>
  </div>
  <!-- Sidebar -->
  <div class="sidebar-wrapper">
    <app-sidebar
      [active]="sidebarSelector"
      (selectorChanged)="setSidebarSelector($event)"
      (logoutClicked)="setLogoutModalVisible(true)">
    </app-sidebar>
    <div class="sidebar-spacer"></div>
  </div>

  <!-- Main Content -->
  <div class="general-data-container">
    <!-- Dashboard Content -->
    <ng-container *ngIf="sidebarSelector === 'dashboard'">
      <div class="content-wrapper">
        <h1 class="general-data-title">General Data</h1>
        <!-- Carbon Credits and General Data -->
        <div class="cc-medical-container">
          <div class="carbon-data-container">
            <h2 class="carbon-data-title">Generation of Carbon Credits</h2>
            <div class="cards-container">
              <app-card
                color="green"
                informationType="Km"
                title="Distance"
                [informationValue]="distance"
                variant="default">
              </app-card>
              <app-card
                color="green"
                informationType=""
                title="Daily quote"
                [informationValue]="ccExchangeRate"
                variant="default">
              </app-card>
              <app-card
                color="green"
                informationType="Planted"
                title="Compensation"
                [informationValue]="compensation"
                variant="default">
              </app-card>
            </div>
            <div class="cards-container">
              <app-card
                color="green"
                informationType="Tons"
                title="Avoided"
                [informationValue]="avoided"
                variant="default">
              </app-card>
              <app-card
                color="green"
                informationType="Generated"
                title="Credits"
                [informationValue]="credits"
                variant="default">
              </app-card>
              <app-card
                color="green"
                informationType="Monetary value"
                title="Amount"
                [informationValue]="totalValue"
                variant="default">
              </app-card>
            </div>
            <!-- Centralize app-carbon-chart -->
            <div class="chart-wrapper">
              <app-carbon-chart></app-carbon-chart>
            </div>
          </div>
          <!-- Medical Data -->
          <div class="medical-data-container">
            <h2 class="carbon-data-title">Medical data of appointments</h2>
            <div class="cards-container">
              <app-card
                color="red"
                informationType=""
                title="City"
                [informationValue]="city"
                variant="default">
              </app-card>
              <app-card
                color="red"
                informationType="Performed"
                title="Appointments"
                [informationValue]="appointments"
                variant="default">
              </app-card>
            </div>
            <div class="cards-container">
              <app-gender-chart></app-gender-chart>
              <app-card
                color="red"
                informationType=""
                title="Patients"
                [informationValue]="patients"
                variant="default">
              </app-card>
            </div>
            <div class="cards-container">
              <app-card
                color="red"
                informationType=""
                title="Physicians"
                [informationValue]="physicians"
                variant="default">
              </app-card>
              <app-card
                color="red"
                informationType=""
                title="Health Professionals"
                [informationValue]="healthProfessionals"
                variant="default">
              </app-card>
            </div>
            <div class="cards-container">
              <app-card
                color="red"
                informationType=""
                title="Medical Specialties"
                [informationValue]="medicalSpecialties"
                variant="default">
              </app-card>
              <app-specialties-chart></app-specialties-chart>
            </div>
          </div>
        </div>
        <!-- Transport Data -->
        <div class="transport-data-container">
          <h2 class="carbon-data-title">Mode of Transport</h2>
          <div class="transport-cards-container">
            <!-- Responsive Layout -->
            <ng-container *ngIf="windowWidth <= 800; else largeScreen">
              <div class="transport-pair">
                <app-card
                  color="black"
                  informationType="Km"
                  title="Car"
                  [informationValue]="car"
                  variant="transport">
                </app-card>
                <app-card
                  color="black"
                  informationType="Km"
                  title="Airplane"
                  [informationValue]="airplane"
                  variant="transport">
                </app-card>
              </div>
              <div class="transport-pair">
                <app-card
                  color="black"
                  informationType="Km"
                  title="Train"
                  [informationValue]="train"
                  variant="transport">
                </app-card>
                <app-card
                  color="black"
                  informationType="Km"
                  title="Bus"
                  [informationValue]="bus"
                  variant="transport">
                </app-card>
              </div>
              <div class="transport-pair">
                <app-card
                  color="black"
                  informationType="Km"
                  title="Ship"
                  [informationValue]="ferries"
                  variant="transport">
                </app-card>
                <app-card
                  color="black"
                  informationType="Km"
                  title="Truck"
                  [informationValue]="truck"
                  variant="transport">
                </app-card>
              </div>
            </ng-container>
            <ng-template #largeScreen>
              <app-card
                color="black"
                informationType="Km"
                title="Car"
                [informationValue]="car"
                variant="transport">
              </app-card>
              <app-card
                color="black"
                informationType="Km"
                title="Airplane"
                [informationValue]="airplane"
                variant="transport">
              </app-card>
              <app-card
                color="black"
                informationType="Km"
                title="Train"
                [informationValue]="train"
                variant="transport">
              </app-card>
              <app-card
                color="black"
                informationType="Km"
                title="Bus"
                [informationValue]="bus"
                variant="transport">
              </app-card>
              <app-card
                color="black"
                informationType="Km"
                title="Ship"
                [informationValue]="ferries"
                variant="transport">
              </app-card>
              <app-card
                color="black"
                informationType="Km"
                title="Truck"
                [informationValue]="truck"
                variant="transport">
              </app-card>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Settings Content -->
    <ng-container *ngIf="sidebarSelector === 'settings'">
      <div class="settings-wrapper_modal">
        <app-settings-modal></app-settings-modal>
      </div>
    </ng-container>

    <!-- Appointments Content -->
    <ng-container *ngIf="sidebarSelector === 'appointments'">
      <div class="content-wrapper">
        <app-table></app-table>
      </div>
    </ng-container>

    <!-- Credit Management Content -->
    <ng-container *ngIf="sidebarSelector === 'creditManagement'">
      <div class="content-wrapper">
        <app-credit-management
          [credits]="credits"
          [totalValue]="totalValue"
        ></app-credit-management>
      </div>
    </ng-container>
  </div>
</div>
