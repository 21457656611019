import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalProviderService {
  private exampleSubject = new BehaviorSubject<string>('initial value');
  example$ = this.exampleSubject.asObservable();

  constructor() {}

  setExample(value: string): void {
    this.exampleSubject.next(value);
  }
}
