import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import User from '../../shared/models/User.model';
import { IAppointment } from '../../shared/models/Appointments';
import { IGetCardsResponse } from 'src/app/shared/models/Cards';

export interface ILoginRequest {
  email: string;
  password: string;
}

interface IConfig {
  defaultFuel: string;
  defaultTransport: string;
  enableResoluteness: boolean;
}

interface ISpeciality {
  speciality: string;
  number: number;
}

interface IChangePasswordRequest {
  code: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  login(data: ILoginRequest): Observable<any> {
    return this.apiService.post('sessions/login', data).pipe(
      tap(response => {
        // Configura todos os cookies necessários como cookies de sessão
        this.cookieService.set('@appCompenSas:token', response.token); // cookie de sessão
        this.cookieService.set('@appCompenSas:useId', response.user.id); // cookie de sessão
        this.cookieService.set('@appCompenSas:defaultTransport', response.user.defaultTransport); // cookie de sessão
        this.cookieService.set('@appCompenSas:defaultFuel', response.user.defaultFuel); // cookie de sessão
        this.cookieService.set('@appCompenSas:enableResoluteness', JSON.stringify(response.user.enableResoluteness)); // cookie de sessão
        // Atualiza o BehaviorSubject com o usuário logado
        this.userSubject.next(response.user);
      })
    );
  }


  logout() {
    this.cookieService.delete('@appCompenSas:token');
    this.cookieService.delete('@appCompenSas:useId');
    this.cookieService.delete('@appCompenSas:defaultTransport');
    this.cookieService.delete('@appCompenSas:defaultFuel');
    this.cookieService.delete('@appCompenSas:enableResoluteness');
    this.userSubject.next(null);
    this.router.navigate(['/']);
  }

  updateConfigs(data: IConfig): Observable<any> {
    return this.apiService.post('api/updateConfigs', data).pipe(
      tap(() => {
        const user = this.userSubject.value;
        if (user) {
          user.defaultFuel = data.defaultFuel;
          user.defaultTransport = data.defaultTransport;
          user.enableResoluteness = data.enableResoluteness;
          this.userSubject.next(user);
          this.cookieService.set('@appCompenSas:defaultTransport', data.defaultTransport); // cookie de sessão
          this.cookieService.set('@appCompenSas:defaultFuel', data.defaultFuel); // cookie de sessão
          this.cookieService.set('@appCompenSas:enableResoluteness', JSON.stringify(data.enableResoluteness)); // cookie de sessão
        }
      })
    );
  }

  getConfigs(): IConfig {
    const defaultFuel = this.cookieService.get('@appCompenSas:defaultFuel');
    const defaultTransport = this.cookieService.get('@appCompenSas:defaultTransport');
    const enableResolutenessToParse = this.cookieService.get('@appCompenSas:enableResoluteness');
    const enableResoluteness = enableResolutenessToParse !== undefined ? JSON.parse(enableResolutenessToParse) : true;

    return { defaultFuel, defaultTransport, enableResoluteness };
  }

  // requestRecoveryPasswordEmail(email: string): Observable<any> {
  //   return this.apiService.post('api/requestRecoveryPasswordEmail', { email });
  // }

  changePassword(data: IChangePasswordRequest): Observable<any> {
    return this.apiService.post('api/changePassword', data);
  }

  getCards(userId: string): Observable<IGetCardsResponse> {
    return this.apiService.get(`appointments/getCards/${userId}`);
  }


  getAppointments(): Observable<IAppointment[]> {
    const id = this.cookieService.get('@appCompenSas:useId');
    return this.apiService.get(`api/getAppointments/${id}`);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }
}
