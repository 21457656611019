import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit, OnDestroy {
  fuel = ['biodiesel', 'gasoline', 'gnv', 'qav', 'diesel oil', 'ethanol', 'flex'];
  cardIcons = ['car', 'bus', 'airplane', 'truck', 'train', 'ship'];
  selected = 'truck';
  selectedRadio = 0;
  enableResoluteness = false;
  windowWidth = 1024;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.updateWindowWidth();
    const {
      defaultTransport,
      defaultFuel,
      enableResoluteness: defaultEnableResoluteness
    } = this.authService.getConfigs();

    this.selected = defaultTransport;
    this.selectedRadio = this.fuel.indexOf(defaultFuel.toLowerCase());
    this.enableResoluteness = defaultEnableResoluteness;

    window.addEventListener('resize', this.updateWindowWidth);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
  }

  updateWindowWidth = (): void => {
    this.windowWidth = window.innerWidth;
  };

  handleUpdateConfig(): void {
    const defaultTransport = this.selected.toLowerCase();
    const defaultFuel = this.fuel[this.selectedRadio];
    this.authService.updateConfigs({ defaultFuel, defaultTransport, enableResoluteness: this.enableResoluteness });
  }

  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  setSelected(card: string): void {
    this.selected = card;
    if (card === 'airplane') {
      this.selectedRadio = 3;
    }
  }

  setSelectedRadio(index: number): void {
    this.selectedRadio = index;
    if (index === 3) {
      this.selected = 'airplane';
    }
  }

  toggleResoluteness(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.enableResoluteness = input.checked;
  }
}
