import { Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-new-password-modal',
  templateUrl: './new-password-modal.component.html',
  styleUrls: ['./new-password-modal.component.scss']
})
export class NewPasswordModalComponent {
  @Output() close = new EventEmitter<void>();
  email: string = '';
  windowWidth: number = window.innerWidth;

  constructor(private userService: UserService) {
    window.addEventListener('resize', this.updateWindowWidth.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateWindowWidth.bind(this));
  }

  requestPassword() {
    this.userService.requestRecoveryPasswordEmail(this.email).subscribe({
      next: () => {
        this.close.emit(); // Fecha a modal após a solicitação ser bem-sucedida
      },
      error: (error) => {
        console.error('Erro ao solicitar a recuperação de senha:', error);
      }
    });
  }

  private updateWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }
}
