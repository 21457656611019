import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/core/services/appointments.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-carbon-chart',
  templateUrl: './carbon-chart.component.html',
  styleUrls: ['./carbon-chart.component.scss']
})
export class CarbonChartComponent implements OnInit {
  dropdownOptions = [
    { value: '10', label: 'Weeks' },
    { value: '20', label: 'Months' }
  ];

  chartData: any[] = [];
  selectedYear: string = '2023'; // Valor inicial

  view: [number, number] = [430, 254]; // Tamanho do gráfico

  colorScheme: Color = {
    name: 'customScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#217D18']
  };

  constructor(private appointmentsService: AppointmentsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.appointmentsService.getCarbonData(this.selectedYear)
      .subscribe(
        (carbonData: number[]) => {
          const newData = carbonData.map((month, index) => ({
            name: (index + 1).toString(),
            value: month
          }));

          this.chartData = newData;
        },
        (error) => {
          console.error('Erro ao carregar dados do gráfico', error);
        }
      );
  }
}
