import { Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CertificateComponent } from '../certificate/certificate.component';
import { DatePipe } from '@angular/common';
import { AppointmentsService } from 'src/app/core/services/appointments.service';
import { IAppointmentsForCertificate } from '../../models/Appointments';
import { CertificateService } from 'src/app/core/services/certificate.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Certificate } from '../../models/Certificate';

type ButtonSelector = 'issueTheReport' | 'supportProjects' | 'sellToPartners'  | 'creditManagement';

@Component({
  selector: 'app-credit-management',
  templateUrl: './credit-management.component.html',
  styleUrl: './credit-management.component.scss',
  providers: [DatePipe]
})
export class CreditManagementComponent implements OnInit, OnDestroy {
  @ViewChild(CertificateComponent) certificateComponent!: CertificateComponent;

  @Input() credits: number = 0;
  @Input() totalValue: number = 0;
  @Input() compensation: number = 0;

  form: FormGroup;
  isFormValid: boolean = false;
  private subscription!: Subscription;

  windowWidth: number;

  buttonSelector: ButtonSelector = 'creditManagement';
  confirmationSelectorButton: ButtonSelector = 'creditManagement';


  columns = [
    // { label: 'ID', key: 'id' },
    { label: 'Nome', key: 'companyName' },
    { label: 'Data de Emissão', key: 'created_at'},
    { label: 'Pegada', key: 'tons'},
    { label: 'Valor', key: 'credits'},
  ];

  rows!:Certificate[]

  actions = [
    {
      label: 'Baixar',
      icon: '/assets/icons/downloadIcon.svg',
      iconSize: '20px',
      iconColor: '#007bff',
      show: (row: any) => true,
      callback: (row: any) => this.downloadCertificate(row)
    }
  ];

   // Dados para o certificado
   certificateData !:Certificate

  modalButtons = [
    {
      label: 'Cancel',
      action: () => this.closeModalHandler(),
      style: 'outlined'
    },
    {
      label: 'Confirm',
      action: () => this.confirmActionHandler(),
      style: 'filled'
    }
  ];

  isModalOpen = false;
  modalConfirmationTitle ='Confirmação';
  modalConfirmationDescription !:string;
  appointmentsForCertificate!: IAppointmentsForCertificate

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private appointmentsService: AppointmentsService,
    private certificateService: CertificateService,
    private toastService: ToastService,

  ){
    this.form = this.fb.group({
      howMuchCredits: ['', Validators.required],
    });
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.validateForm();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validateForm() {
    this.subscription = this.form.valueChanges.subscribe(() => {
      const value = this.form.get('howMuchCredits')?.value || 0;
      this.compensation = parseFloat(value);

      const isValid = this.compensation > 0 && this.compensation <= this.totalValue;

      this.isFormValid = isValid;
    });
  }

  clickHandlerCancel(value:ButtonSelector){
    this.buttonSelector = value;
    this.confirmationSelectorButton = 'creditManagement'
    if(value === 'creditManagement'){
      this.form.reset();
      this.compensation=0;
    }
  }

  clickHandler(value:ButtonSelector){
    this.confirmationSelectorButton = value;
    this.appointmentsService.findAppointmentsForCertificate(this.compensation).subscribe(data =>{
      const { credits , appointments}= data;
      if(!(Array.isArray(appointments) && appointments.length) ){
        this.toastService.showInfo('O valor inserido não pode ser processado. Por favor, tente com um valor alternativo.','Atenção!');
        return;
      }
      this.appointmentsForCertificate = data;
      this.modalConfirmationDescription = `Gostaria de confirmar a geração do certificado com o valor R$: ${credits.toFixed(2)}`
      this.compensation = parseFloat(credits.toFixed(2))
      this.modalButtons = [
        {
          label: 'Cancel',
          action: () => this.closeModalHandler(),
          style: 'outlined'
        },
        {
          label: 'Confirm',
          action: () => this.confirmActionHandler(),
          style: 'filled'
        }
      ];
      this.openDynamicModal()
      if(value === 'creditManagement'){
        this.form.reset();
        this.compensation=0;
      }
    })
  }

  clickHandlerDownloadCertificatePDF(certificateData: Certificate) {
    this.certificateComponent.downloadPDF(certificateData);
  }

  openDynamicModal() {
    this.isModalOpen = true;
  }

  closeModalHandler() {
    this.isModalOpen = false;
    this.confirmationSelectorButton='creditManagement';
    this.appointmentsForCertificate = {
      appointments: [],
      credits: 0,
      year: '',
      tons: 0,
    };
  }

  downloadCertificate(data:any){
    this.buttonSelector = 'issueTheReport'
    this.certificateComponent.downloadPDF(data);
  }

  confirmActionHandler() {
    this.isModalOpen = false;
    this.certificateService.createCertificate(this.appointmentsForCertificate).subscribe({
      next: (data: Certificate) => {
        this.certificateData= data
        this.modalConfirmationDescription = `Relatório de compensação gerado com sucesso!`
        this.modalButtons = [
          {
            label: 'Fechar',
            action: () => this.closeModalHandler(),
            style: 'outlined'
          }
        ];
        this.toastService.showSuccess('Gerado certificado!','Sucesso!');
        this.openDynamicModal()
        this.getAllCertificate();
        this.buttonSelector = this.confirmationSelectorButton;
      },
      error: (error: any) => {
        console.warn('Erro ao gerar certificado:', error);
        this.closeModalHandler()
        this.clickHandlerCancel('creditManagement')
        this.toastService.showError('Erro ao gerar certificado!','Erro!');
      },
      complete: () => {
      }
    });

  }

  getAllCertificate(){
    this.certificateService.getAllCertificate().subscribe((data)=>{
      this.rows = data;
    })
  }
}
