<div class="margin-button2">
  <div>
    <h1 class="general-data-title">Gerenciar meus créditos</h1>
    <div class="cc-medical-container margin-button3">
      <div class="carbon-data-container">
        <h2 class="carbon-data-title">
          Decida o que será feito com os créditos de carbono acumulados
        </h2>
        <div class="cards-container" >
          <app-card *ngIf="buttonSelector === 'creditManagement' ||'issueTheReport'"
            color="green"
            informationType="Generated"
            title="Credits"
            [informationValue]="credits"
            variant="default">
          </app-card>
          <app-card *ngIf="buttonSelector === 'creditManagement' ||'issueTheReport'"
            color="green"
            informationType="Monetary value"
            title="Amount"
            [informationValue]="totalValue"
            variant="default">
          </app-card>
          <app-card *ngIf="buttonSelector === 'issueTheReport'"
            color="green"
            informationType="Tons planted"
            title="Compensation"
            [informationValue]="compensation"
            variant="default">
          </app-card>
        </div>
      </div>
    </div>

    <form [formGroup]="form" *ngIf="buttonSelector === 'creditManagement'">
      <div >
        <div class="flex-align-center margin-button2">
          <span class="custom-font">
            Quantos créditos você quer gerenciar:
          </span>
          <app-input
            id="credits-input"
            [type]="'number'"
            [placeholder]="''"
            formControlName="howMuchCredits"
            [width]="windowWidth > 800 ? '10vw' : '25vw'"
            [inputBackgroundColor]="'#D9D9D9'"
            [borderColor]="'transparent'"
          ></app-input>
        </div>

        <div class="flex-align-center margin-button2">
          <span class="custom-font">O que você gostaria de fazer com esses valor?</span>
        </div>
        <div class="flex-button margin-button2 gap15">
          <app-button
            [text]="'Emitir Relatórios'"
            [backgroundColor]="'#1E2732'"
            iconPosition="right"
            iconAlt="check"
            icon="assets/CardIcons/check.svg"
            [width]="windowWidth > 800 ? '20rem' : '20rem'"
            [disabled]="!isFormValid"
            (onClick)="clickHandler('issueTheReport')"
          >
          </app-button>
          <app-button
            [text]="'Apoiar Projetos'"
            [backgroundColor]="'#1E2732'"
            iconPosition="right"
            iconAlt="people"
            icon="assets/CardIcons/people.svg"
            [width]="windowWidth > 800 ? '20rem' : '20rem'"
            [disabled]="true"
          >
          </app-button>
        </div>
        <div class="button-relative">
          <app-button
            [text]="'Vender para Parceiros'"
            [height]="'6rem'"
            [width]="windowWidth > 800 ? '20rem' : '20rem'"
            [backgroundColor]="'#1E2732'"
            iconPosition="right"
            iconAlt="enterprise"
            icon="assets/CardIcons/enterprise.svg"
            [disabled]="true"
          >
          </app-button>
        </div>
      </div>

    </form>

    <div class="flex-align-end flex-justify-content-flex-start gap15 padding-right" *ngIf="buttonSelector === 'issueTheReport'">
      <div class="content-wrapper-certificado">
        <span>ilustração do certificado</span>
        <!-- <img [src]="'assets/test_images/certificado.svg'" [alt]="'certificado'" class="img_certificate" /> -->
        <app-certificate [certificateData]="certificateData"></app-certificate>
      </div>
      <div class="flex-align-end flex-column gap15">
        <app-button
            [text]="'Baixar'"
            [height]="'6rem'"
            [width]="windowWidth > 960 ? '20rem' : '10rem'"
            [backgroundColor]="'#28a745'"
            [disabled]="false"
            (onClick)="clickHandlerDownloadCertificatePDF(certificateData)"
          >
          </app-button>
          <app-button
            [text]="'Voltar'"
            [height]="'6rem'"
            [width]="windowWidth > 960 ? '20rem' : '10rem'"
            [backgroundColor]="'#1E2732'"
            [disabled]="false"
            (onClick)="clickHandlerCancel('creditManagement')"
          >
          </app-button>
      </div>
    </div>

    <div class="margin-top3 margin-button2" *ngIf="buttonSelector === 'issueTheReport'">
      <div class="margin-button2">
        <span class="span_table">Histórico de relatórios emitidos</span>
      </div>
      <app-dynamic-table
        [columns]="columns"
        [rows]="rows"
        [actions]="actions"
        [showActions]="true"
        [headerBackgroundColor]="'#1e2732'"
        [headerTextColor]="'#fafafa'"
        [headerFontFamily]="'Poppins'"
        [headerFontSize]="'14px'"
        [headerFontWeight]="'200'"
        [bodyBackgroundColor]="'#ffffff'"
        [bodyTextColor]="'#000000'"
        [bodyFontFamily]="'Arial'"
        [bodyFontSize]="'14px'"
        [bodyFontWeight]="'normal'"
        [borderColor]="'#dee2e6'"
        [showBorder]="false"
        [borderWidth]="2"
      ></app-dynamic-table>


    </div>
  </div>
</div>

<!-- Modal Dinâmica -->
<app-dynamic-modal
  *ngIf="isModalOpen"
  [title]="modalConfirmationTitle"
  [description]="modalConfirmationDescription"
  [buttons]="modalButtons"
  [position]="'center'"
  (closeModal)="closeModalHandler()">
</app-dynamic-modal>
