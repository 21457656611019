import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  @Output() setOpen = new EventEmitter<boolean>();

  constructor(private authService: AuthService, private router: Router) {}

  close() {
    this.setOpen.emit(false);
  }

  confirm() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
