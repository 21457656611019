import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private activeSubject = new BehaviorSubject<'dashboard' | 'settings' | 'appointments' | 'creditManagement'>('dashboard');
  active$ = this.activeSubject.asObservable();

  private logoutModalSubject = new BehaviorSubject<boolean>(false);
  logoutModal$ = this.logoutModalSubject.asObservable();

  constructor(private cookieService: CookieService) {}

  setActive(active: 'dashboard' | 'settings' | 'appointments' | 'creditManagement') {
    this.activeSubject.next(active);
  }

  setLogoutModal(show: boolean) {
    this.logoutModalSubject.next(show);
  }

  getUserId(): string {
    return this.cookieService.get('@appCompenSas:useId');
  }
}
