<div class="modal-container">
  <div class="header">
    <p class="header-text">Logout</p>
  </div>
  <p class="text">Are you sure?</p>
  <div class="buttons-container">
    <button class="button outlined" (click)="close()">RETURN</button>
    <button class="button filled" (click)="confirm()">CONFIRM</button>
  </div>
</div>
