<div class="container mt-3">
  <div class="table-responsive">
    <table class="table table-bordered table-striped custom-table">
      <thead class="thead-dark custom-thead">
        <tr>
          <th *ngFor="let column of columns">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody class="custom-tbody">
        <tr *ngFor="let row of rows">
          <td *ngFor="let column of columns">
            <!-- Coluna 'Transport' -->
            <ng-container *ngIf="column.key === 'transport'; else checkGeneratedCC">
              <img [src]="row[column.key] | transportIcon" alt="Transport Icon">
            </ng-container>
            <!-- Coluna 'CC Gerado' -->
            <ng-template #checkGeneratedCC>
              <ng-container *ngIf="column.key === 'generatedCC'; else defaultCell">
                <div class="credit-wrapper">
                  <span>{{ row[column.key] }}</span>
                  <img src="/assets/creditIcon.svg" alt="Credit Icon">
                </div>
              </ng-container>
            </ng-template>
            <!-- Para outras colunas -->
            <ng-template #defaultCell>{{ row[column.key] }}</ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
