<div class="dynamic-table-container">
  <div class="table-responsive">
    <table
      class="table custom-table"
      [ngClass]="{'no-borders': !showBorder}"
      [ngStyle]="{
        'border-color': showBorder ? borderColor : 'transparent',
        '--border-width': borderWidth + 'px'
      }"
    >
      <thead [ngStyle]="{
        'background-color': headerBackgroundColor,
        'color': headerTextColor,
        'border-color': showBorder ? borderColor : 'transparent',
        'font-family': headerFontFamily,
        'font-size': headerFontSize,
        'font-weight': headerFontWeight
      }">
        <tr>
          <th *ngFor="let column of columns" [ngStyle]="{'border-color': showBorder ? borderColor : 'transparent'}">{{ column.label }}</th>
          <th *ngIf="showActions" [ngStyle]="{'border-color': showBorder ? borderColor : 'transparent'}">Ações</th>
        </tr>
      </thead>
      <tbody [ngStyle]="{
        'background-color': bodyBackgroundColor,
        'color': bodyTextColor,
        'border-color': showBorder ? borderColor : 'transparent',
        'font-family': bodyFontFamily,
        'font-size': bodyFontSize,
        'font-weight': bodyFontWeight
      }">
        <tr *ngFor="let row of rows">
          <td *ngFor="let column of columns" [ngStyle]="{'border-color': showBorder ? borderColor : 'transparent'}">
            {{ row[column.key] }}
          </td>
          <td *ngIf="showActions" [ngStyle]="{'border-color': showBorder ? borderColor : 'transparent'}">
            <ng-container *ngFor="let action of actions">
              <button *ngIf="action.show(row)" (click)="action.callback(row)" style="margin-right: 1rem;">
                <img [src]="action.icon" [ngStyle]="{'width': action.iconSize, 'color': action.iconColor}" alt="{{ action.label }} Icon">
              </button>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
