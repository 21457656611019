<div>
  <!-- Conteúdo do Certificado -->
  <div #contentToConvert class="certificado-container-view">
    <div class="header">
      <div class="title">
        <h1>Certificado de Compensação</h1>
        <h3>Certificamos que</h3>
        <h2 class="nome">{{certificateData?.companyName}}</h2>
        <p *ngIf="certificateData?.cnpj" class="cnpj">CNPJ: {{ certificateData?.cnpj}}</p>
        <p>{{certificateData?.created_at }}</p>
      </div>
    </div>

    <div class="body">
      <p>Compensou</p>
      <h2 class="toneladas">{{certificateData?.tons}} toneladas</h2>
      <p>de dióxido de carbono relativas à sua pegada de carbono administrativa do ano de {{certificateData?.year}}. Isso equivale a {{certificateData?.credits}} créditos de carbono.</p>
    </div>

    <div class="footer">
      <img src="assets/certificateImage/Assinatura_Adriana.png" alt="Assinatura" class="signature">
      <img *ngIf="certificateData?.qrcode" [src]="certificateData?.qrcode" alt="QR Code" class="qrcode">
      <img src="assets/certificateImage/Logo_Compensas.png" alt="Compens SAS" class="logo">
    </div>

    <div class="sidebar">
      <img src="assets/certificateImage/Barra_lateral.png" alt="Lateral com Selo">
    </div>
  </div>
</div>
