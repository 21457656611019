<div class="modal-container">
  <div class="header">
    <p class="header-text">New Password</p>
  </div>
  <p class="enter-email-text">Enter your email to request a new password</p>
  <input
    class="email-input"
    placeholder="E-mail"
    [(ngModel)]="email"
  />
  <div class="buttons-container">
    <button
      class="button outlined"
      [style.width]="windowWidth < 800 ? '47%' : ''"
      (click)="close.emit()"
    >
      RETURN
    </button>
    <button
      class="button filled"
      [style.width]="windowWidth < 800 ? '47%' : ''"
      (click)="requestPassword()"
    >
      CONFIRM
    </button>
  </div>
</div>
