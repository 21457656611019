<div class="global-container">
  <div class="dropdown-container">
    <p class="dropdown-title">Tons of carbon</p>
    <app-dropdown [width]="'27rem'" [initialValue]="'10'" [options]="dropdownOptions"></app-dropdown>
  </div>
  <div class="chart-container">
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="chartData"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="'Months'"
      [yAxisLabel]="'Tons of Carbon'"
      [barPadding]="8"
      [roundEdges]="false"
    >
    </ngx-charts-bar-vertical>
  </div>
</div>
