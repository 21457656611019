<div class="modal-container">
  <div class="header">
    <p class="header-text">Change Password</p>
  </div>

  <form [formGroup]="passwordForm">
    <div class="password-container">
      <p class="password-text">New Password</p>
      <input
        type="password"
        formControlName="password"
        [ngStyle]="{ width: windowWidth > 800 ? '440px' : '100%' }"
        placeholder="New Password"
        [ngClass]="{'error': errorVisible && passwordForm.get('password')?.invalid}"
      />
    </div>

    <div class="password-container">
      <p class="password-text">Confirm your password</p>
      <input
        type="password"
        formControlName="confirmPassword"
        [ngStyle]="{ width: windowWidth > 800 ? '440px' : '100%' }"
        placeholder="Confirm Password"
        [ngClass]="{'error': errorVisible && passwordForm.get('confirmPassword')?.invalid}"
      />
    </div>

    <p *ngIf="errorVisible" class="error-text">Passwords do not match</p>

    <div class="buttons-container">
      <button
        type="button"
        (click)="setOpen?.(false)"
        [ngStyle]="{ width: windowWidth < 800 ? '47%' : 'auto' }"
      >
        RETURN
      </button>
      <button
        type="button"
        (click)="onConfirmClick()"
        [ngStyle]="{ width: windowWidth < 800 ? '47%' : 'auto' }"
      >
        CONFIRM
      </button>
    </div>
  </form>
</div>
