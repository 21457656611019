import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

interface DropdownOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() width: string = '100%';
  @Input() initialValue: string = '10';
  @Input() options: DropdownOption[] = [
    { value: '10', label: 'Weeks' },
    { value: '20', label: 'Months' }
  ];

  range = new FormControl('');

  ngOnInit(): void {
    this.range.setValue(this.initialValue);
  }

  onChange(event: any): void {
    this.range.setValue(event.target.value);
  }
}
