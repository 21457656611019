// src/app/core/services/user.service.ts
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { CookieService } from 'ngx-cookie-service';

interface IUpdateConfigsRequest {
  defaultFuel: string;
  defaultTransport: string;
  enableResoluteness: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService
  ) {}

  updateConfigs(data: IUpdateConfigsRequest): Observable<void> {
    return this.apiService.post<void>('changeConfigs', data).pipe(
      tap(() => {
        this.cookieService.set('@appCompenSas:defaultTransport', data.defaultTransport);
        this.cookieService.set('@appCompenSas:defaultFuel', data.defaultFuel);
        this.cookieService.set('@appCompenSas:enableResoluteness', JSON.stringify(data.enableResoluteness));
      })
    );
  }

  requestRecoveryPasswordEmail(email: string): Observable<string> {
    return this.apiService.get<string>(`forgotPassword/${email}`, { responseType: 'text' as 'json' });
  }

  changePassword(code: string, password: string): Observable<void> {
    return this.apiService.post<void>(
      `changePassword/`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${code}`
        }
      }
    );
  }
}
