import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Certificate } from '../../models/Certificate';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent {

  @ViewChild('contentToConvert') contentToConvert!: ElementRef;

  @Input() certificateData!: Certificate | null | undefined

  downloadPDF(certificateData: Certificate) {
    this.certificateData = certificateData;
    const htmlContent = `
      <div style="width: 1122px; height: 794px; background-color: white; position: relative; padding: 30px; font-family: Arial, sans-serif;">
        <div style="padding-right: 240px;">
          <div style="position: relative;">
            <h1 style="font-size: 48px; font-weight: bold; margin-bottom: 10px;">Certificado de Compensação</h1>
            <h3 style="font-size: 18px; margin: 10px 0;">Certificamos que</h3>
            <h2 style="font-size: 36px; font-weight: bold; margin: 10px 0;">${this.certificateData.companyName}</h2>
            ${this.certificateData?.cnpj ? `<p style="font-size: 20px; font-weight: bold; margin: 5px 0;">CNPJ: ${this.certificateData?.cnpj}</p>` : ''}
            <p style="font-size: 16px; margin: 5px 0;">${this.certificateData.created_at}</p>
          </div>
        </div>
        <div style="margin-top: 20px; padding-right: 240px;">
          <p style="font-size: 18px; margin: 10px 0;">Compensou</p>
          <h2 style="font-size: 32px; font-weight: bold; margin: 20px 0;">${this.certificateData.tons} toneladas</h2>
          <p style="font-size: 18px; margin: 10px 0;">de dióxido de carbono relativas à sua pegada de carbono administrativa do ano de ${this.certificateData.year}. Isso equivale a ${this.certificateData.credits} créditos de carbono.</p>
        </div>
        <div style="display: flex; justify-content: space-around; align-items: flex-end; margin-top: 200px; padding-right: 230px; height: 100px;">
          <img src="assets/certificateImage/Assinatura_Adriana.png" alt="Assinatura" style="width: 150px;">
          ${this.certificateData.qrcode ? `<img src="${this.certificateData.qrcode}" alt="QR Code" style="width: 90px; margin: 0 20px 50px;">` : ''}
          <img src="assets/certificateImage/Logo_Compensas.png" alt="Compens SAS" style="width: 120px; margin-bottom: 50px;">
        </div>
        <div style="position: absolute; top: 0; right: 0; height: 100%;">
          <img src="assets/certificateImage/Barra_lateral.png" alt="Lateral com Selo" style="height: 100%;">
        </div>
      </div>
    `;

    const container = document.createElement('div');
    container.innerHTML = htmlContent;
    document.body.appendChild(container);

    html2canvas(container).then(canvas => {
      const imgWidth = 297; // Largura A4 horizontal em mm
      const pageHeight = 210; // Altura A4 horizontal em mm
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('Certificado_Compensacao.pdf');

      // Remove the temporary container after PDF is generated
      document.body.removeChild(container);
    });
    this.clearCertificateData();
  }

  clearCertificateData(): void {
    this.certificateData = {
      id : '',
      created_at : '',
      cnpj : '',
      tons : '',
      year : '',
      credits : '',
      qrcode : '',
      exchange_rate : '',
      companyId : '',
      companyName : '',
      companyEmail : '',
    };
  }
}
