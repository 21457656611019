<div class="modal-container" [ngClass]="{'modal-container-small': windowWidth < 500}">
  <p class="settings-title">Settings</p>
  <div class="resolution-div">
    <p class="resolution-text">Enable resolution</p>
    <label class="switch">
      <input type="checkbox" [checked]="enableResoluteness" (change)="toggleResoluteness($event)">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="mode-container">
    <p class="mode-title">Mode of Transport</p>
    <div class="mode-selector-container">
      <div *ngFor="let card of cardIcons.slice(0, 3)" (click)="setSelected(card)" class="icon-selector">
        <img [src]="selected.toLowerCase() === card.toLowerCase() ? 'assets/SelectedRadio.svg' : 'assets/UnselectedRadio.svg'" class="radio-button"/>
        <div class="transport-wrapper">
          <img [src]="selected.toLowerCase() === card.toLowerCase() ? 'assets/CardIcons/Green' + capitalize(card) + '.svg' : 'assets/CardIcons/' + capitalize(card) + 'Icon.svg'" class="transport-image"/>
          <p class="transport-label">{{ card.toUpperCase() }}</p>
        </div>
      </div>
    </div>
    <div class="mode-selector-container">
      <div *ngFor="let card of cardIcons.slice(3, 6)" (click)="setSelected(card)" class="icon-selector">
        <img [src]="selected.toLowerCase() === card.toLowerCase() ? 'assets/SelectedRadio.svg' : 'assets/UnselectedRadio.svg'" class="radio-button"/>
        <div class="transport-wrapper">
          <img [src]="selected.toLowerCase() === card.toLowerCase() ? 'assets/CardIcons/Green' + capitalize(card) + '.svg' : 'assets/CardIcons/' + capitalize(card) + 'Icon.svg'" class="transport-image"/>
          <p class="transport-label">{{ card.toUpperCase() }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mode-container">
    <p class="mode-title">Type of Fuel</p>
    <div [ngClass]="{'fuel-selector-container': windowWidth >= 800, 'fuel-selector-container-responsive': windowWidth < 800}">
      <div *ngFor="let fuelType of fuel; let i = index" (click)="setSelectedRadio(i)" class="fuel-selector">
        <img [src]="selectedRadio === i ? 'assets/SelectedRadio.svg' : 'assets/UnselectedRadio.svg'" class="radio-button"/>
        <p class="fuel-text">{{ capitalize(fuelType) }}</p>
      </div>
    </div>
  </div>
  <button (click)="handleUpdateConfig()">SAVE</button>
</div>
