import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() isInactive: boolean = false;
  @Input() isPassword: boolean = false;
  @Input() placeholder: string = '';
  @Input() width: string = '364px';
  @Input() borderColor: string = '#949494';
  @Input() autoComplete: string = 'off';
  @Input() id: string = '';
  @Input() type: string | null = null;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() step: number | null = null;

  // Variáveis para cores dinâmicas
  @Input() inputTextColor: string = '#000000';  // Cor padrão do texto
  @Input() inputBackgroundColor: string = '#fafafa'; // Cor de fundo padrão do input

  visibility: boolean = true;
  private innerValue: string = '';

  onTouched: () => void = () => {};
  onChange: (value: string) => void = () => {};

  ngOnInit(): void {
    this.visibility = !this.isPassword;
  }

  get content(): string {
    return this.innerValue;
  }

  @Input()
  set content(value: string) {
    this.innerValue = value;
    this.onChange(value);
  }

  onContentChange(value: string): void {
    this.content = value;
    this.onChange(value);
  }

  toggleVisibility(): void {
    this.visibility = !this.visibility;
  }

  // Função para definir o tipo de input
  getInputType(): string {
    if (this.type) {
      return this.type;
    }
    return this.isPassword && !this.visibility ? 'password' : 'text';
  }

  // Função para verificar se o tipo é numérico
  isNumberType(): boolean {
    return this.type === 'number';
  }

  writeValue(value: any): void {
    this.innerValue = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

   changeTextColor(newColor: string) {
    this.inputTextColor = newColor;
  }

  changeBackgroundColor(newColor: string) {
    this.inputBackgroundColor = newColor;
  }
}
