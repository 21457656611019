<button
  [ngClass]="{
    'btn-filled': variant === 'filled',
    'btn-outlined': variant === 'outlined',
    'disabled-button': disabled
  }"
  [ngStyle]="{
    width: width,
    height: height,
    backgroundColor: disabled ? '#ccc' : backgroundColor,
    color: textColor,
    borderColor: borderColor,
    fontSize: fontSize,
    fontWeight: fontWeight,
    borderRadius: borderRadius,
    cursor: disabled ? 'not-allowed' : cursor
  }"
  [disabled]="disabled"
  (click)="handleClick()"
>
  <ng-container *ngIf="icon && iconPosition === 'left'">
    <img [src]="icon" [alt]="iconAlt" class="button-icon" />
  </ng-container>

  {{ text }}

  <ng-container *ngIf="icon && iconPosition === 'right'">
    <img [src]="icon" [alt]="iconAlt" class="button-icon" />
  </ng-container>
</button>
