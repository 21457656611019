<div class="chart-component-container">
  <p class="chart-title">Gender of patients</p>
  <div class="main-container">
    <div class="chart-container">
      <ngx-charts-pie-chart
        [results]="[
          { name: 'Male', value: sexData.male },
          { name: 'Female', value: sexData.female }
        ]"
        [scheme]="colorScheme"
        [labels]="false"
        [doughnut]="false"
      >
      </ngx-charts-pie-chart>

    </div>
    <div class="chart-label-container">
      <div class="label-item">
        <div class="label-color1"></div>
        <p class="label-info">Male</p>
      </div>
      <div class="label-item">
        <div class="label-color2"></div>
        <p class="label-info">Female</p>
      </div>
    </div>
  </div>
</div>
