import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GenderChartComponent } from './gender-chart/gender-chart.component';
import { SpecialtiesChartComponent } from './specialties-chart/specialties-chart.component';
import { CarbonChartComponent } from './carbon-chart/carbon-chart.component';
import { SharedModule } from '../../shared.module';
import { NgChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  declarations: [
    GenderChartComponent,
    SpecialtiesChartComponent,
    CarbonChartComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    NgChartsModule,
    NgxChartsModule
  ],
  exports: [
    GenderChartComponent,
    SpecialtiesChartComponent,
    CarbonChartComponent
  ]
})
export class ChartsModule { }
