import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  private createHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    const token = this.cookieService.get('@appCompenSas:token');
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    if (customHeaders) {
      customHeaders.keys().forEach(key => {
        headers = headers.set(key, customHeaders.get(key) as string);
      });
    }

    return headers;
  }

  get<T>(endpoint: string, options?: any): Observable<T> {
    const headers = this.createHeaders(options?.headers);
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`, { ...options, headers, observe: 'events' })
      .pipe(
        filter((event: HttpEvent<T>) => event instanceof HttpResponse),
        map((response: HttpResponse<T>) => response.body as T)
      );
  }

  post<T>(endpoint: string, data: any, options?: any): Observable<T> {
    const headers = this.createHeaders(options?.headers);
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, data, { ...options, headers, observe: 'events' })
      .pipe(
        filter((event: HttpEvent<T>) => event instanceof HttpResponse),
        map((response: HttpResponse<T>) => response.body as T)
      );
  }


  postBody<T>(endpoint: string, data: any, options?: any): Observable<any> {
    const headers = this.createHeaders(options?.headers);
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, data, { ...options, headers, observe: 'body' });
  }

  put<T>(endpoint: string, data: any, options?: any): Observable<T> {
    const headers = this.createHeaders(options?.headers);
    return this.http.put<T>(`${this.baseUrl}/${endpoint}`, data, { ...options, headers, observe: 'events' })
      .pipe(
        filter((event: HttpEvent<T>) => event instanceof HttpResponse),
        map((response: HttpResponse<T>) => response.body as T)
      );
  }

  patch<T>(endpoint: string, data: any, options?: any): Observable<T> {
    const headers = this.createHeaders(options?.headers);
    return this.http.patch<T>(`${this.baseUrl}/${endpoint}`, data, { ...options, headers, observe: 'events' })
      .pipe(
        filter((event: HttpEvent<T>) => event instanceof HttpResponse),
        map((response: HttpResponse<T>) => response.body as T)
      );
  }

  delete<T>(endpoint: string, options?: any): Observable<T> {
    const headers = this.createHeaders(options?.headers);
    return this.http.delete<T>(`${this.baseUrl}/${endpoint}`, { ...options, headers, observe: 'events' })
      .pipe(
        filter((event: HttpEvent<T>) => event instanceof HttpResponse),
        map((response: HttpResponse<T>) => response.body as T)
      );
  }
}
