<div class="container" [style.width]="width">
  <div class="input-container" [style.border-color]="borderColor" [class.inactive]="isInactive">
    <input
      [id]="id"
      [type]="getInputType()"
      [placeholder]="placeholder"
      [(ngModel)]="content"
      (ngModelChange)="onContentChange($event)"
      [attr.min]="isNumberType() ? min : null"
      [attr.max]="isNumberType() ? max : null"
      [attr.step]="isNumberType() ? step : null"
      [attr.autocomplete]="autoComplete"
      [class.password]="isPassword"
      [class.inactive]="isInactive"
      [ngStyle]="{ 'background-color': inputBackgroundColor, color: inputTextColor }"
    />

    <div *ngIf="isPassword && !type" class="eye-icon-container" (click)="toggleVisibility()">
      <img class="eye-icon" [src]="visibility ? 'assets/InputEye/VisibilityOff.svg' : 'assets/InputEye/VisibilityOn.svg'" />
    </div>
  </div>
</div>
