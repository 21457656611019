import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transportIcon'
})
export class TransportIconPipe implements PipeTransform {

  transform(value: string): string {
    const fixedCardSplited = value?.replace(' ', '').split('');
    if (fixedCardSplited) fixedCardSplited[0] = fixedCardSplited[0].toUpperCase();
    const fixedCard = fixedCardSplited ? fixedCardSplited.join('') : '';
    return `/assets/CardIcons/${fixedCard}Icon.svg`;
  }

}
