import { Component, Input, OnInit } from '@angular/core';

interface IColumn {
  label: string;
  key: string;
}

interface IAction {
  label: string;
  icon: string;
  iconSize?: string;
  iconColor?: string;
  show: (row: any) => boolean;
  callback: (row: any) => void;
}

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {
  @Input() columns: IColumn[] = [];
  @Input() rows: any[] = [];
  @Input() actions: IAction[] = [];
  @Input() showActions: boolean = true;
  @Input() headerBackgroundColor: string = '#1e2732';
  @Input() headerTextColor: string = '#fafafa';
  @Input() headerFontFamily: string = 'Poppins';
  @Input() headerFontSize: string = '28px';
  @Input() headerFontWeight: string = 'bold';
  @Input() borderColor: string = '#dee2e6';
  @Input() showBorder: boolean = true; // Controla a exibição das bordas
  @Input() bodyTextColor: string = '#000000';
  @Input() bodyBackgroundColor: string = '#ffffff';
  @Input() bodyFontFamily: string = 'Arial';
  @Input() bodyFontSize: string = '10px';
  @Input() bodyFontWeight: string = 'normal';
  @Input() borderWidth: number = 1; // Define a espessura da borda

  constructor() {}

  ngOnInit(): void {}
}
