import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss']
})
export class DynamicModalComponent {
  @Input() title: string = 'Modal Title';
  @Input() description: string = 'Modal description text goes here.';
  @Input() buttons: { label: string, action: () => void, style?: string }[] = [];
  @Input() position: string = 'center';
  @Output() closeModal = new EventEmitter<void>();

  get modalPositionClass(): string {
    switch (this.position) {
      case 'top':
        return 'modal-top';
      case 'bottom':
        return 'modal-bottom';
      case 'top-left':
        return 'modal-top-left';
      case 'top-right':
        return 'modal-top-right';
      case 'bottom-left':
        return 'modal-bottom-left';
      case 'bottom-right':
        return 'modal-bottom-right';
      case 'center-left':
        return 'modal-center-left';
      case 'center-right':
        return 'modal-center-right';
      case 'center':
        return 'modal-center';
      default:
        return 'modal-center';
    }
  }


  close() {
    this.closeModal.emit();
  }
}
