import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text: string = 'Button';
  @Input() width: string = '20rem';
  @Input() height: string = '4.8rem';
  @Input() variant: 'filled' | 'outlined' = 'filled';
  @Input() backgroundColor: string = '#007bff'; // Cor de fundo padrão
  @Input() textColor: string = '#fff'; // Cor do texto padrão
  @Input() borderColor: string = '#007bff'; // Cor da borda padrão

  // Novas propriedades
  @Input() fontSize: string = '18px'; // Tamanho do texto
  @Input() fontWeight: string = '900'; // Peso da fonte
  @Input() borderRadius: string = '1.2rem'; // Border-radius padrão
  @Input() cursor: string = 'pointer'; // Tipo de cursor padrão

  // Propriedades do ícone
  @Input() icon: string | null = null; // Caminho do ícone
  @Input() iconAlt: string = 'Icon'; // Texto alternativo do ícone
  @Input() iconPosition: 'left' | 'right' = 'left'; // Posição do ícone (esquerda ou direita)

  @Input() disabled: boolean = false; // Propriedade para desabilitar o botão

  @Output() onClick = new EventEmitter<void>();

  handleClick(): void {
    this.onClick.emit();
  }
}



/* exemplo de uso
Com ícone à esquerda:
<app-button
  text="Custom Button"
  width="15rem"
  height="3rem"
  variant="filled"
  backgroundColor="#28a745"
  textColor="#fff"
  borderColor="#28a745"
  fontSize="16px"
  fontWeight="700"
  borderRadius="8px"
  cursor="pointer"
  icon="assets/icons/check.svg"  <!-- Caminho do ícone -->
  iconPosition="left"            <!-- Posição à esquerda -->
  iconAlt="Check"                <!-- Texto alternativo -->
  (onClick)="yourClickHandler()"
></app-button>

Com ícone à direita:
<app-button
  text="Submit"
  width="15rem"
  height="3rem"
  variant="outlined"
  backgroundColor="transparent"
  textColor="#000"
  borderColor="#000"
  fontSize="16px"
  fontWeight="700"
  borderRadius="8px"
  cursor="pointer"
  icon="assets/icons/arrow-right.svg"  <!-- Caminho do ícone -->
  iconPosition="right"                 <!-- Posição à direita -->
  iconAlt="Arrow Right"                <!-- Texto alternativo -->
  (onClick)="yourClickHandler()"
></app-button>

*/
