<div class="modal-wrapper" [ngClass]="modalPositionClass">
  <div class="modal-container">
    <div class="header">
      <span class="header-text">{{ title }}</span>
    </div>
    <div class="text">{{ description }}</div>
    <div class="buttons-container">
      <button *ngFor="let button of buttons" class="button"
              [ngClass]="button.style" (click)="button.action()">
        {{ button.label }}
      </button>
    </div>
  </div>
</div>
