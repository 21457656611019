import { Component, OnInit } from '@angular/core';
import { IAppointment } from '../../models/Appointments';
import { AppointmentsService } from 'src/app/core/services/appointments.service';

interface IRow {
  id: number;
  idPatient: string;
  idDoctor: string;
  transport: string;
  distance: string;
  speciality: string;
  fuel: string;
  state: string;
  generatedCC: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  columns: { name: string, key: keyof IRow }[] = [];
  rows: IRow[] = [];
  windowWidth = 1024;

  constructor(
    private appointmentsService: AppointmentsService
  ) {}

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    this.columns = [
      { name: 'ID Paciente', key: 'idPatient' },
      { name: 'ID Médico', key: 'idDoctor' },
      { name: 'Meio de Transporte', key: 'transport' },
      { name: 'Distância Evitada', key: 'distance' },
      { name: 'Especialidade Médica', key: 'speciality' },  // Adicionei esta linha
      { name: 'Combustível', key: 'fuel' },
      { name: 'Estado', key: 'state' },
      { name: 'CC Gerado', key: 'generatedCC' }
    ];

    this.getRows();
  }

  getRows() {
    this.appointmentsService.getAppointments().subscribe((appointments: IAppointment[]) => {
      this.rows = appointments.map((appointment, index) => ({
        id: index + 1,
        idPatient: appointment.patientId,
        idDoctor: appointment.doctorId,
        transport: appointment.transport,
        distance: `${(appointment.distance / 1000).toFixed(2)} Km`,
        speciality: appointment.doctorSpeciality,
        fuel: appointment.fuel,
        state: appointment.state,
        generatedCC: appointment.generatedCC.toFixed(2)
      }));
    });
  }

  getTransportIcon(transport: string): string {
    const fixedCard = transport.replace(' ', '');
    return `/assets/CardIcons/${fixedCard}Icon.svg`;
  }
}
