import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/core/services/appointments.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-gender-chart',
  templateUrl: './gender-chart.component.html',
  styleUrls: ['./gender-chart.component.scss']
})
export class GenderChartComponent implements OnInit {
  sexData: { male: number; female: number } = { male: 0, female: 0 };
  colorScheme: Color = {
    name: 'custom',
    selectable: true,
    group: 'ordinal' as ScaleType,
    domain: ['#1E2732', '#D9D9D9']
  };

  constructor(private appointmentsService: AppointmentsService) {}

  ngOnInit(): void {
    this.getSexData();
  }

  getSexData(): void {
    this.appointmentsService.getSexData().subscribe(data => {
      this.sexData = data;
      // Uso para teste do gráfico
      // this.sexData = { male: 10, female: 15 };
    });
  }
}
