import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import ISpeciality from 'src/app/shared/models/Speciality.model';
import {IAppointment, IAppointmentsForCertificate } from 'src/app/shared/models/Appointments';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
  ) {}

  getSpecialties(): Observable<ISpeciality[]> {
    return this.apiService.get('appointments/getSpecialityData');
  }

  getAppointments(): Observable<IAppointment[]> {
    const id = this.cookieService.get('@appCompenSas:useId');
    return this.apiService.get(`appointments/getTable/${id}`);
  }

  getCarbonData(year: string): Observable<number[]> {
    return this.apiService.get(`appointments/getCarbonPerTimeData/${year}`);
  }
  getSexData(): Observable<{ male: number; female: number }> {
    const userId = this.cookieService.get('@appCompenSas:useId');
    return this.apiService.get(`appointments/getSexData/${userId}`);
  }

  findAppointmentsForCertificate(targetValue:number): Observable<IAppointmentsForCertificate> {
    const companyId = this.cookieService.get('@appCompenSas:useId');
    const body = {
      companyId,
      targetValue
    }
    return this.apiService.post(`appointments/findAppointmentsForCertificate/`, body);
  }
}
