<div class="chart-component-container">
  <p class="chart-title">Medical Specialties</p>
  <div class="main-container">
    <div class="chart-container">
      <ngx-charts-pie-chart
        [results]="chartData$ | async"
        [scheme]="colorScheme"
        [labels]="false"
        [doughnut]="true"
        [arcWidth]="0.55"
        [legend]="false"
      >
      </ngx-charts-pie-chart>
    </div>
    <div class="chart-label-container">
      <ng-container *ngIf="(chartData$ | async) as chartData">
        <div *ngFor="let item of chartData.slice(0, 6)" class="label-item">
          <div
            class="label-color"
            [ngStyle]="{'background-color': colorScheme.domain[chartData.indexOf(item) % colorScheme.domain.length]}"
          ></div>
          <p class="label-info">{{ item.name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
