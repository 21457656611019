<div class="sidebar-container">
  <div class="upper-container">
    <img class="logo-img" [src]="getLogoSrc()" [ngClass]="{'small-logo': windowWidth < 800}">
    <div class="buttons-container">
      <div *ngFor="let button of buttons" (click)="setActive(button.key)" class="button-div">
        <div class="button-div2">
          <button class="sidebar-button" [ngClass]="{'active': active === button.key}">
            <img class="sidebar-button-icon" [src]="getButtonIcon(button.key)">
            <span class="sidebar-button-text" *ngIf="windowWidth > 800">{{ button.label }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="profile-container">
    <img class="profile-image" [src]="getProfileImageSrc()">
    <div class="profile-text-container" [ngClass]="{'small-profile-text-container': windowWidth < 800}">
      <p class="profile-name">{{ getProfileName() }}</p>
      <p class="profile-email">......</p>
    </div>
    <img class="logout-button" src="assets/Log_Out.svg" (click)="showLogoutModal()">
  </div>
</div>
