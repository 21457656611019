import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { IAppointmentsForCertificate } from 'src/app/shared/models/Appointments';
import { Certificate } from 'src/app/shared/models/Certificate';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
  ) { }

  createCertificate(appointmentsForCertificate:IAppointmentsForCertificate): Observable<Certificate> {

    const companyId = this.cookieService.get('@appCompenSas:useId');
    const body ={
      ...appointmentsForCertificate,
      companyId
    }
    return this.apiService.postBody(`certificate/create/`,body);
  }

  getAllCertificate(): Observable<Certificate[]> {
    const id = this.cookieService.get('@appCompenSas:useId');
    return this.apiService.get(`certificate/getAllCertificate/${id}`);
  }
}
