import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

interface ISelectLanguage {
  code: string,
  name: string,
  flag: string
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  windowWidth: number;
  code: string | null;
  newPasswordModalOpen: boolean = false;
  changePasswordModalOpen: boolean = false;
  currentLang: string;
  languages = [
    { code: 'en', name: 'English', flag: 'assets/flags/us.svg' },
    { code: 'pt', name: 'Português', flag: 'assets/flags/br.svg' }
  ];

  private resizeListener: () => void;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.code = null;
    this.windowWidth = window.innerWidth;
    this.resizeListener = this.onResize.bind(this);
    this.currentLang = 'en'; // Idioma padrão
  }

  ngOnInit(): void {
    this.updateWindowWidth();
    window.addEventListener('resize', this.resizeListener);
    this.translate.setDefaultLang(this.currentLang); // Definir idioma padrão
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
  }

  login(): void {
    this.authService.login(this.loginForm.value).pipe(
      catchError((error) => {
        this.toastService.showError('An error occurred during login. Please try again.','Warning');
        console.error('Login error: ', error);
        return throwError(() => new Error(error));
      })
    ).subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.router.navigate(['/dashboard']);
        this.toastService.showSuccess('Welcome!','Success!');
      } else {
        this.toastService.showError('Incorrect email or password!','Warning');
      }
    });
  }

  openNewPasswordModal(): void {
    this.newPasswordModalOpen = true;
  }

  openChangePasswordModal(): void {
    this.changePasswordModalOpen = true;
  }

  private onResize(): void {
    this.updateWindowWidth();
  }

  private updateWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  // changeLanguage(lang: ISelectLanguage): void {
  //   console.log('lang', lang)
  //   if (lang) {
  //     this.currentLang = lang.code;
  //     this.translate.use(lang.code);
  //   }
  // }
  changeLanguage(event: Event): void {
    // console.log('event', event)
    const selectElement = event.target as HTMLSelectElement;
    const lang = selectElement.value;
    if (lang) {
      this.currentLang = lang;
      this.translate.use(lang);
    }
  }

}
